import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoAdminOperation, LazySvgoAdminRejectReason, LazySvgoAgentHomeLogo, LazySvgoAlertUpdateData, LazySvgoAlertWarning, LazySvgoAlreadyConfirm, LazySvgoAppointmentSuccess, LazySvgoArea, LazySvgoArrowPureLeft, LazySvgoArrowRight, LazySvgoArrowsRolate, LazySvgoBath, LazySvgoBed, LazySvgoCalendar, LazySvgoCentral, LazySvgoCheckMarked, LazySvgoCirclesCircle1, LazySvgoCloseDeal, LazySvgoConfirmLoguot, LazySvgoContact, LazySvgoCreatePost, LazySvgoCurvesCurve1, LazySvgoCurvesCurve2, LazySvgoCurvesCurve3, LazySvgoCurvesCurve4, LazySvgoDocumentUpdate, LazySvgoEyeOffOutline, LazySvgoEyeOutline, LazySvgoFacebookSymbol, LazySvgoFileDocumentSigned, LazySvgoFileDuplicate, LazySvgoFileLimit, LazySvgoFileNotFound, LazySvgoFitness, LazySvgoFloor, LazySvgoGoogleIcon, LazySvgoGoogleMap, LazySvgoHaveComfirm, LazySvgoHistory, LazySvgoHome, LazySvgoIconBg, LazySvgoIconCompleted, LazySvgoIconContract, LazySvgoIconHandMoney, LazySvgoIconHomenayoo, LazySvgoIconLink, LazySvgoIconMarketing, LazySvgoIconMoney, LazySvgoIconNayoo, LazySvgoIconReferTransference, LazySvgoIconRight, LazySvgoIconSalesBuys, LazySvgoIconSalesClosure, LazySvgoIdentity, LazySvgoLand, LazySvgoLineIcon, LazySvgoLogoKsb, LazySvgoMail, LazySvgoMarker, LazySvgoMarketingOffline, LazySvgoMarketingOnline, LazySvgoMyPost, LazySvgoNoSmile, LazySvgoNormalSmile, LazySvgoNotFound, LazySvgoNotLineLiff, LazySvgoPdfFile, LazySvgoPerformance, LazySvgoPhoneRing, LazySvgoPhone, LazySvgoPreviewSaleReport, LazySvgoReferList, LazySvgoReviewAgent, LazySvgoSafety, LazySvgoSaleReport, LazySvgoSatisfaction, LazySvgoShield, LazySvgoSoldOut, LazySvgoStar, LazySvgoSuccess, LazySvgoSuperSmile, LazySvgoTesting, LazySvgoThankyou, LazySvgoTraining, LazySvgoTransferenceReport, LazySvgoTrash, LazySvgoUploadFile, LazySvgoUpload, LazySvgoVerified, LazySvgoVisitPerson, LazySvgoWaitingLateProcess, LazySvgoWaiting, LazySvgoWebLogo } from '#components'
const lazyGlobalComponents = [
  ["SvgoAdminOperation", LazySvgoAdminOperation],
["SvgoAdminRejectReason", LazySvgoAdminRejectReason],
["SvgoAgentHomeLogo", LazySvgoAgentHomeLogo],
["SvgoAlertUpdateData", LazySvgoAlertUpdateData],
["SvgoAlertWarning", LazySvgoAlertWarning],
["SvgoAlreadyConfirm", LazySvgoAlreadyConfirm],
["SvgoAppointmentSuccess", LazySvgoAppointmentSuccess],
["SvgoArea", LazySvgoArea],
["SvgoArrowPureLeft", LazySvgoArrowPureLeft],
["SvgoArrowRight", LazySvgoArrowRight],
["SvgoArrowsRolate", LazySvgoArrowsRolate],
["SvgoBath", LazySvgoBath],
["SvgoBed", LazySvgoBed],
["SvgoCalendar", LazySvgoCalendar],
["SvgoCentral", LazySvgoCentral],
["SvgoCheckMarked", LazySvgoCheckMarked],
["SvgoCirclesCircle1", LazySvgoCirclesCircle1],
["SvgoCloseDeal", LazySvgoCloseDeal],
["SvgoConfirmLoguot", LazySvgoConfirmLoguot],
["SvgoContact", LazySvgoContact],
["SvgoCreatePost", LazySvgoCreatePost],
["SvgoCurvesCurve1", LazySvgoCurvesCurve1],
["SvgoCurvesCurve2", LazySvgoCurvesCurve2],
["SvgoCurvesCurve3", LazySvgoCurvesCurve3],
["SvgoCurvesCurve4", LazySvgoCurvesCurve4],
["SvgoDocumentUpdate", LazySvgoDocumentUpdate],
["SvgoEyeOffOutline", LazySvgoEyeOffOutline],
["SvgoEyeOutline", LazySvgoEyeOutline],
["SvgoFacebookSymbol", LazySvgoFacebookSymbol],
["SvgoFileDocumentSigned", LazySvgoFileDocumentSigned],
["SvgoFileDuplicate", LazySvgoFileDuplicate],
["SvgoFileLimit", LazySvgoFileLimit],
["SvgoFileNotFound", LazySvgoFileNotFound],
["SvgoFitness", LazySvgoFitness],
["SvgoFloor", LazySvgoFloor],
["SvgoGoogleIcon", LazySvgoGoogleIcon],
["SvgoGoogleMap", LazySvgoGoogleMap],
["SvgoHaveComfirm", LazySvgoHaveComfirm],
["SvgoHistory", LazySvgoHistory],
["SvgoHome", LazySvgoHome],
["SvgoIconBg", LazySvgoIconBg],
["SvgoIconCompleted", LazySvgoIconCompleted],
["SvgoIconContract", LazySvgoIconContract],
["SvgoIconHandMoney", LazySvgoIconHandMoney],
["SvgoIconHomenayoo", LazySvgoIconHomenayoo],
["SvgoIconLink", LazySvgoIconLink],
["SvgoIconMarketing", LazySvgoIconMarketing],
["SvgoIconMoney", LazySvgoIconMoney],
["SvgoIconNayoo", LazySvgoIconNayoo],
["SvgoIconReferTransference", LazySvgoIconReferTransference],
["SvgoIconRight", LazySvgoIconRight],
["SvgoIconSalesBuys", LazySvgoIconSalesBuys],
["SvgoIconSalesClosure", LazySvgoIconSalesClosure],
["SvgoIdentity", LazySvgoIdentity],
["SvgoLand", LazySvgoLand],
["SvgoLineIcon", LazySvgoLineIcon],
["SvgoLogoKsb", LazySvgoLogoKsb],
["SvgoMail", LazySvgoMail],
["SvgoMarker", LazySvgoMarker],
["SvgoMarketingOffline", LazySvgoMarketingOffline],
["SvgoMarketingOnline", LazySvgoMarketingOnline],
["SvgoMyPost", LazySvgoMyPost],
["SvgoNoSmile", LazySvgoNoSmile],
["SvgoNormalSmile", LazySvgoNormalSmile],
["SvgoNotFound", LazySvgoNotFound],
["SvgoNotLineLiff", LazySvgoNotLineLiff],
["SvgoPdfFile", LazySvgoPdfFile],
["SvgoPerformance", LazySvgoPerformance],
["SvgoPhoneRing", LazySvgoPhoneRing],
["SvgoPhone", LazySvgoPhone],
["SvgoPreviewSaleReport", LazySvgoPreviewSaleReport],
["SvgoReferList", LazySvgoReferList],
["SvgoReviewAgent", LazySvgoReviewAgent],
["SvgoSafety", LazySvgoSafety],
["SvgoSaleReport", LazySvgoSaleReport],
["SvgoSatisfaction", LazySvgoSatisfaction],
["SvgoShield", LazySvgoShield],
["SvgoSoldOut", LazySvgoSoldOut],
["SvgoStar", LazySvgoStar],
["SvgoSuccess", LazySvgoSuccess],
["SvgoSuperSmile", LazySvgoSuperSmile],
["SvgoTesting", LazySvgoTesting],
["SvgoThankyou", LazySvgoThankyou],
["SvgoTraining", LazySvgoTraining],
["SvgoTransferenceReport", LazySvgoTransferenceReport],
["SvgoTrash", LazySvgoTrash],
["SvgoUploadFile", LazySvgoUploadFile],
["SvgoUpload", LazySvgoUpload],
["SvgoVerified", LazySvgoVerified],
["SvgoVisitPerson", LazySvgoVisitPerson],
["SvgoWaitingLateProcess", LazySvgoWaitingLateProcess],
["SvgoWaiting", LazySvgoWaiting],
["SvgoWebLogo", LazySvgoWebLogo],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
